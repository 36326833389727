/*----------------------------------------*/
/*  03. SLIDER CSS START
/*----------------------------------------*/

.single-slider{
    position: relative;
    @include background();
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: -moz-linear-gradient( 60deg, #690ed0 0%, #170da2 100%);
        background-image: -ms-linear-gradient( 60deg, #690ed0 0%, #170da2 100%);
        background-image: -webkit-linear-gradient( 60deg, #690ed0 0%, #170da2 100%);
        background-image: linear-gradient( 60deg, #690ed0 0%, #170da2 100%);
        opacity: .9;
    }
    &-2{
        &::before{
            display: none;
        }
        &.slick-active{
            & .slider__content-2{
                &::before{
                    width: 250px;
                    @media #{$xs}{
                        width: 200px;
                    }
                }
            }
        }
    }
}

.slick-active .single-slider-2 .slider__content-2::before {
    width: 250px;
}

.slider{
    &__area{
        position: relative;
        & .slick-dots{
            display: none;
        }
        &-2{
            & .slick-dots{
                display: inline-block !important;
                position: absolute;
                bottom: 30px;
                left: 140px;
                & li{
                    display: inline-block;
                    &.slick-active{
                        & button{
                            background: $white;
                        }
                    }
                    & button{
                        font-size: 0;
                        width: 14px;
                        height: 14px;
                        background: transparent;
                        border: 2px solid rgba($color: $white, $alpha: .3);
                        display: inline-block;
                        @include border-radius(50%);
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &__height{
        min-height: 950px;
        @media #{$sm}{
            min-height: 700px;
        }
        @media #{$xs}{
            min-height: 600px;
        }
        &-2{
            @media #{$md}{
                min-height: 800px;
            }
        }
    }
    &__content{
        position: relative;
        z-index: 1;
        & > span{
            color: $grey-3;
            font-size: 20px;
            display: inline-block;
            margin-bottom: 17px;
        }
        & h1{
            font-size: 70px;
            color: $white;
            font-weight: 900;
            margin-bottom: 38px;
            @media #{$md}{
                font-size: 50px;
            }
            @media #{$sm}{
                font-size: 40px;
            }
            @media #{$xs}{
                font-size: 30px;
            }
        }
        &-2{
            position: relative;
            & > span{
                padding: 4px 10px;
                color: #e6e6e6;
                background: rgba($color: $white, $alpha: .1);
                @include border-radius(6px);
                display: inline-block;
                margin-bottom: 10px;
                font-size: 16px;
            }
            & h1{
                margin-bottom: 15px;
                @media #{$laptop}{
                    font-size: 60px;
                }
                @media #{$lg}{
                    font-size: 60px;
                }
            }
            & p{
                color: #e6e6e6;
                margin-bottom: 45px;
            }
            &::before{
                position: absolute;
                content: '';
                left: -100px;
                top: -115px;
                width: 0;
                height: 600px;
                background-image: -moz-linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                background-image: -webkit-linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                background-image: -ms-linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                background-image: linear-gradient( -120deg, rgb(255,36,247) 0%, rgb(137,35,255) 100%);
                opacity: .8;
                z-index: -1;
                border-top-left-radius: 100px;
                @media #{$lg}{
                    top: -90px;
                    height: 500px;
                }
                @media #{$md}{
                    top: -90px;
                    height: 500px;
                }
                @media #{$sm}{
                    top: -55px;
                    height: 400px;
                }
                @media #{$xs}{
                    top: -65px;
                    height: 400px;
                    left: auto;
                    right: 0px;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    -moz-transform: scaleX(-1);
                    -ms-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                }
            }
        }
        &-3{
            &::before{
                display: none;
            }
            & p{
                display: inline-block;
                margin-bottom: 40px;

            }
        }
        &-4{
            padding-right: 65px;
            & p{
                margin-bottom: 55px;
                line-height: 30px;
            }
        }
    }
    &__btn{
        & a{
            &:first-child{
                margin-right: 30px;
                @media #{$xs}{
                    margin-right: 5px;
                }
            }
        }
    }
    &__play{
        @media #{$xs}{
            margin-top: 30px;
        }
        &-btn{
            display: inline-block;
            width: 90px;
            height: 90px;
            line-height: 86px;
            text-align: center;
            background: transparent;
            font-size: 25px;
            color: $white;
            border: 2px solid rgba($color: $white, $alpha: .2);
            @include border-radius(50%);
            animation: pulse 2s infinite;
            &:hover{
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
    }
}

/* pulse btn */
@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
}
}
.home_slider_1{
    background: url(../img/slider/slider-1.jpg);
    background-repeat: no-repeat;
}
.home_slider_2{
    background: url(../img/slider/slider-2.jpg);
    background-repeat: no-repeat;
}
.h1_slider_wrapper{
    position: relative;
    z-index: 99;
}

/*fadein custom*/
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}



.slick-active .slider__content span,.slick-active .slider__content h1,.slick-active .slider__content p,.slick-active .slider__content .slider__btn,.slick-active .hero-slider-btn,.slick-active .h4-span {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slick-active .slider__content span {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.slick-active .slider__content h1{
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}
.slick-active .slider__content p{
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
}
.slick-active .slider__content .slider__btn{
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
}
.hero__content{
    & span{
        -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
    }
    & h1{
         -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay:0.5s;
        animation-delay:0.5s;
        -webkit-animation-duration:0.5s;
        animation-duration:0.5s;
    }
    & p{
         -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay:0.7s;
        animation-delay:0.7s;
        -webkit-animation-duration:0.7s;
        animation-duration:0.7s;
    }
    & a{
         -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-delay:0.9s;
        animation-delay:0.9s;
        -webkit-animation-duration:0.9s;
        animation-duration:0.9s;
    }
}
