/*----------------------------------------*/
/*  10. BRAND CSS START
/*----------------------------------------*/

.brand{
    &__subtitle{
        & p{
            font-size: 16px;
            padding-right: 120px;
            margin-top: 30px;
            line-height: 30px;
        }
    }
    &__item{
        margin-bottom: 90px;
        text-align: center;
        & img{
            width: inherit !important;
            display: inline-block !important;
        }
    }
}




